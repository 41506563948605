/* eslint-disable import/prefer-default-export */
import * as events from '../events';
import genericEvents from './generic-events';

export const FlowModule = {
    name: 'TwoClick and OTP Orange',
    logger: {},
    dispatcher: {},
    uiControl: {},
    tracker: {},
    integrator: {},
    productUrl: '',
    locale: '',
    productSlug: '',
    plan: '',
    identity: '',
    identified: false,
    identifyCallFinished: false,
    identifyTimeout: 10, // seconds
    internalCall: false,
    territory: '',
    trackConversions: false,
    metadata: {},
    step: '',
    flowSteps: {
        INIT: 'init',
        IDENTIFY_SUCCESS: 'identify success',
        CONFIRM: 'confirm',
        OTP_CHALLENGE: 'otp challenge',
        OTP_VALIDATE: 'otp validate',
        IDENTIFY_ERROR: 'identify error',
        SUBSCRIPTION_FAILED: 'subscription failed',
        SUBSCRIPTION_SUCCEEDED: 'subscription success',
    },
    pageStates: {
        IDENTITY_PENDING: 'identity pending',
        SUBSCRIBE: 'subscribe',
        CONFIRM: 'confirm',
        OTP_CHALLENGE: 'otp challenge',
        OTP_VALIDATE: 'otp validate',
        ERROR: 'error',
        SUCCESS: 'success',
    },

    /**
     * Method to initialise the flow. This will be binding all the event listeners
     * needed to implement the two click flow
     */
    init() {
        this.integrator.appendBaseData({ territory: this.territory });
        this.setFlowStep(this.flowSteps.INIT);
        this.logger.debug(`Flow (${this.name}): initialising flow`);
        this.tracker.setupDefaultGAConsent();
        this.bindClickEvents();
        this.bindTrackingEvents();
        this.checkForSubscription();
        this.bindIdentifyIframeEvents();
        if (!this.internalCall) {
            this.identifyCustomer();
        } else {
            this.showHeFlow();
        }
    },

    /**
     * Set the current step of the flow
     *
     * @param {string} flowStep
     */
    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;

        this.setPageStateForFlowStep(flowStep);
    },

    /**
     * Set the current state of the page
     *
     * @param {string} flowStep
     */
    setPageStateForFlowStep(flowStep) {
        switch (flowStep) {
        case this.flowSteps.INIT:
            this.uiControl.setPageState(this.pageStates.IDENTITY_PENDING);
            this.uiControl.showElement(this.uiControl.controls.spinner);
            break;
        case this.flowSteps.OTP_CHALLENGE:
            this.uiControl.setPageState(this.pageStates.OTP_CHALLENGE);
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.hideElement(this.uiControl.controls.otp.error);
            this.uiControl.showElement(this.uiControl.controls.otp.container);
            this.uiControl.showElement(this.uiControl.controls.otp.challenge);
            break;
        case this.flowSteps.OTP_VALIDATE:
            this.uiControl.setPageState(this.pageStates.OTP_VALIDATE);
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.hideElement(this.uiControl.controls.otp.challenge);
            this.uiControl.hideElement(this.uiControl.controls.otp.error);
            this.uiControl.showElement(this.uiControl.controls.otp.validate);
            break;
        case this.flowSteps.CONFIRM:
            this.uiControl.setPageState(this.pageStates.CONFIRM);
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.showElement(this.uiControl.controls.confirm);
            break;
        case this.flowSteps.SUBSCRIPTION_SUCCEEDED:
            this.uiControl.setPageState(this.pageStates.SUCCESS);
            break;
        case this.flowSteps.IDENTIFY_SUCCESS:
            this.uiControl.setPageState(this.pageStates.SUBSCRIBE);
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.uiControl.showElement(this.uiControl.controls.subscribe);
            break;
        case this.flowSteps.IDENTIFY_ERROR:
        case this.flowSteps.SUBSCRIPTION_FAILED:
        default:
            this.uiControl.setPageState(this.pageStates.ERROR);
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
            break;
        }
    },

    identifyCustomer() {
        this.logger.debug(`Flow (${this.name}): identifying customer`);

        setTimeout(this.checkIdentify.bind(this), this.identifyTimeout * 1000);

        this.integrator.heIframeIdentify(
            {
                return: window.location.origin + window.location.pathname,
                metadata: this.metadata,
            }
        );
    },

    /**
     * Add listener for postMessages
     */
    bindIdentifyIframeEvents() {
        this.logger.debug(`Flow (${this.name}): binding iframe events`);
        window.addEventListener('message', this.onMessage.bind(this), false);
    },

    /**
     * Binding the click events on the first button (#flow-subscribe) and on the
     * confirm button (#flow-confirm)
     */
    bindClickEvents() {
        this.logger.debug(`Flow (${this.name}): binding click events`);

        genericEvents.bindLanguageSwitcherEvent(this);
        genericEvents.bindFlowExitEvent(this);

        // listen for first click
        this.dispatcher.addEventListener(events.FIRST_CLICK, () => {
            this.logger.debug(`Flow (${this.name}): handling first button click - subscribe`);
            this.tracker.handleGAConsent(true);
            this.tracker.track(events.FIRST_CLICK);
            this.setFlowStep(this.flowSteps.CONFIRM);
        });

        // listen for second click
        this.dispatcher.addEventListener(events.SECOND_CLICK, () => {
            this.logger.debug(`Flow (${this.name}): handling second button click - confirm`);
            this.tracker.track(events.SECOND_CLICK);
            this.uiControl.hideElement(this.uiControl.controls.confirm);

            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);

            this.callHeSubscribe();
        });

        // listen for challenge submit
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_SUBMIT, () => {
            if (this.uiControl.controls.otp.msisdn.value === '') {
                return;
            }
            this.logger.debug(`Flow (${this.name}): handling challenge submit`);
            this.uiControl.showElement(this.uiControl.controls.spinner);

            this.callGenerateOtp();
        });

        // listen for challenge validate
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_VALIDATE, () => {
            this.logger.debug(`Flow (${this.name}): handling challenge validation`);
            this.uiControl.showElement(this.uiControl.controls.spinner);
            this.uiControl.hideElement(this.uiControl.controls.otp.error);

            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);

            this.callOtpSubscribe();
        });
    },

    /**
     * Binding the tracking events
     */
    bindTrackingEvents() {
        this.dispatcher.addEventListener(events.SUBSCRIPTION_FAILED, (event) => {
            this.tracker.track(events.SUBSCRIPTION_FAILED, {
                data: {
                    reference: event.reference,
                    status_code: event.status_code,
                },
            });
        });

        this.dispatcher.addEventListener(events.SUBSCRIPTION_SUCCEEDED, (event) => {
            this.tracker.track(events.SUBSCRIPTION_SUCCEEDED, {
                data: {
                    subscription: event.jwt,
                    reference: event.reference,
                },
            });

            // set a cookie to tell the browser that this user is subscribed
            /* eslint prefer-template: "off" */
            document.cookie = 'auth=' + event.jwt + '; max-age=43200';

            if (this.trackConversions) {
                const redirector = () => {
                    this.redirectToProduct(event.jwt);
                };

                this.uiControl.showElement(this.uiControl.controls.success.container);
                window.setTimeout(redirector.bind(this), 2500);
                this.uiControl.displayMessage('Please wait while you are redirected to the service');
                this.tracker.conversion({
                    subscription: event.jwt,
                });
            } else {
                this.redirectToProduct(event.jwt);
            }
        });
    },

    callHeSubscribe() {
        const onSuccess = (response) => {
            this.logger.debug(`Flow (${this.name}): received integrator response`, response);

            if (response.status === 'SUCCESS') {
                if (response.new) {
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_SUCCEEDED);
                    this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                        jwt: response.jwt,
                        reference: response.reference,
                    });
                } else {
                    this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                        data: {
                            jwt: response.jwt,
                            reference: response.reference,
                        },
                    });
                    this.redirectToProduct(response.jwt);
                }
            } else {
                this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                    reference: response.reference,
                });
            }
        };

        const onError = (response) => {
            this.logger.error(`Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
            });
        };

        this.metadata['fraud_detection_id'] = this.metadata['fraud_unique_id']

        this.uiControl.showElement(this.uiControl.controls.spinner);
        this.integrator.LPSubscribeRequest({
            identity: this.identity,
            metadata: this.metadata,
        }, "lp/he/subscribe", onSuccess, onError);
    },

    callGenerateOtp() {
        const onSuccess = (response) => {
            this.logger.debug(`Flow (${this.name}): received integrator response`, response);

            if (response.status === 'SUCCESS') {
                this.token = response.token;
                this.setFlowStep(this.flowSteps.OTP_VALIDATE);
                this.tracker.track(events.OTP_GENERATE_SUCCESS, {
                    data: {
                        code_token: response.token,
                        reference: response.reference,
                    },
                });
            } else {
                this.setFlowStep(this.flowSteps.OTP_CHALLENGE);
                this.uiControl.showElement(this.uiControl.controls.otp.error);
                this.tracker.track(events.OTP_GENERATE_FAILURE, {
                    data: {
                        reference: response.reference,
                    },
                });
            }
        };

        const onError = (response) => {
            this.logger.error(`Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.OTP_CHALLENGE);
            this.uiControl.showElement(this.uiControl.controls.otp.error);
            this.tracker.track(events.OTP_GENERATE_FAILURE, {
                data: {
                    reference: response.reference,
                },
            });
        };
        this.integrator.LPOtpGenerateCustomRequest({
            plan: this.plan,
            product: this.productSlug,
            metadata: this.metadata,
            msisdn: this.uiControl.controls.otp.msisdn.value,
        }, "lp/otp/generate", onSuccess, onError);
    },

    callOtpSubscribe() {
        const onSuccess = (response) => {
            this.logger.debug(`Flow (${this.name}): received integrator response`, response);
            this.uiControl.hideElement(this.uiControl.controls.spinner);

            if (response.status === 'SUCCESS') {
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                    jwt: response.jwt,
                    reference: response.reference,
                });
            } else {
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                    reference: response.reference,
                });
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.showElement(this.uiControl.controls.otp.error);
            }
        };

        const onError = (response) => {
            this.logger.error(`Flow (${this.name}): received error from integrator`, response);
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.showElement(this.uiControl.controls.otp.error);
        };

        this.integrator.LPOtpSubscribeCustomRequest({
            plan: this.plan,
            product: this.productSlug,
            metadata: this.metadata,
            token: this.token,
            code: this.uiControl.controls.otp.code.value,
        },"lp/otp/subscribe",  onSuccess, onError);
    },

    /**
     * Redirect to product with an auth token
     *
     * @param {string} jwt
     */
    redirectToProduct(jwt) {
        // remove unload message
        window.onbeforeunload = null;

        this.tracker.track(events.REDIRECT_TO_PRODUCT);

        let redirectUrl = this.productUrl;
        const queryConcat = (redirectUrl.indexOf('?') !== -1) ? '&' : '?';

        redirectUrl = `${this.productUrl}${queryConcat}auth=${jwt}&locale=${this.locale}`;

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);

        window.location.assign(redirectUrl);
    },

    onMessage(event) {
        this.logger.debug(`Flow (${this.name}): received iframe message`, event);

        const originDomain = event.origin.split('://');
        const integratorDomain = this.integrator.baseUrl.split('://');
        const validDomains = [
            integratorDomain[1],
            'sso.orange.com',
            'tb1n.orange.com',
            'partners-enrichment.orange.be',
        ];

        if (typeof originDomain[1] === 'undefined' || originDomain[1] === '' || validDomains.indexOf(originDomain[1]) === -1) {
            this.logger.debug(`Flow (${this.name}): failed to validate message origin.`, originDomain, validDomains);
            return;
        }
        this.identifyCallFinished = true;

        const response = event.data;
        const trackingData = {
            identity: response.identity,
            identified: response.identified,
            reference: response.reference,
        };

        this.uiControl.hideElement(this.uiControl.controls.spinner);

        if (response.status === 'SUCCESS' && response.status_code === 200) {
            this.identity = response.identity;
            this.identified = response.identified;

            if (response.identified) {
                this.setFlowStep(this.flowSteps.IDENTIFY_SUCCESS);
                this.tracker.track(events.IDENTIFY_SUCCESS, { data: trackingData });
            } else {
                this.setFlowStep(this.flowSteps.OTP_CHALLENGE);
                this.tracker.track(events.IDENTIFY_FAILURE, { data: trackingData });
                this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
            }
        } else { // 'ERROR'
            this.setFlowStep(this.flowSteps.IDENTIFY_ERROR);
            this.tracker.track(events.IDENTIFY_ERROR, { data: trackingData });
            this.dispatcher.dispatchEvent(events.IDENTIFY_ERROR);
        }
    },

    showHeFlow() {
        this.uiControl.hideElement(this.uiControl.controls.spinner);
        this.setFlowStep(this.flowSteps.IDENTIFY_SUCCESS);
    },

    checkIdentify() {
        if (!this.identifyCallFinished) {
            this.setFlowStep(this.flowSteps.IDENTIFY_ERROR);
            this.tracker.track(events.IDENTIFY_ERROR, {
                data: {
                    error: `request timeout reached - ${this.identifyTimeout}s`,
                },
            });
        }
    },

    /**
     * Check if we have an auth Cookie and if so redirect to the product
     */
    checkForSubscription() {
        const authCookie = document.cookie.split('; ').find(row => row.startsWith('auth'));
        if (typeof authCookie === 'string') {
            const jwt = authCookie.split('=')[1];
            this.redirectToProduct(jwt);
        }
    },
};

window.FlowModule = FlowModule;
